.heading{
    text-align: center;
    font-size: 25px;
}

a{
    text-decoration: none;
}
/*transactions*/
.transactions li {
    margin: 30px auto;
    border: 1px solid #f2f2f2;
    box-shadow: 3px 3px 5px rgba(50, 50, 50, 0.1);
    padding: 20px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    border-left: 10px solid #1f9751;
  }
  
  .transactions .name {
    color: #181818;
    font-size: 1.4em;
  }
  
  .transactions .amount {
    margin-left: auto;
    /* margin-right: 40px; */
    color: #000000;
    font-weight: bold;
    font-size: 1.6em;
  }
  
  .transactions button {
    position: absolute;
    top: 0;
    right: 0;
    background: #ddd;
    color: #777;
    border: none;
    padding: 12px 8px;
    text-align: center;
    line-height: 0;
    font-size: 0.9em;
    cursor: pointer;
  }
  
  
  .subtext{
    font-size: 0.8em;
    color: #646464;
  }