@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}
.btn {
  background: none;
  border: 2px solid #1f9751;
  padding: 6px 12px;
  border-radius: 4px;
  color: #1f9751;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn:hover {
  background: #1f9751;
  color: #fff;
}


.toggler {
  width: 72px;
  /* margin: 10px auto; */
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.toggler input {
  display: none !important;
}

.toggler label {
  display: block;
  position: relative;
  width: 72px;
  height: 36px;
  border: 1px solid #d6d6d6;
  border-radius: 36px;
  background: #e4e8e8;
  cursor: pointer;
}

.toggler label::after {
  display: block;
  border-radius: 100%;
  background-color: #d7062a;
  content: '';
  -webkit-animation-name: toggler-size;
          animation-name: toggler-size;
  -webkit-animation-duration: 0.15s;
          animation-duration: 0.15s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-direction: forwards;
          animation-direction: forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

.toggler label::after, .toggler label .toggler-on, .toggler label .toggler-off {
  position: absolute;
  top: 50%;
  left: 25%;
  width: 26px;
  height: 26px;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  transition: left 0.15s ease-in-out, background-color 0.2s ease-out, width 0.15s ease-in-out, height 0.15s ease-in-out, opacity 0.15s ease-in-out;
}

.toggler input:checked + label::after, .toggler input:checked + label .toggler-on, .toggler input:checked + label .toggler-off {
  left: 75%;
}

.toggler input:checked + label::after {
  background-color: #50ac5d;
  -webkit-animation-name: toggler-size2;
          animation-name: toggler-size2;
}

.toggler .toggler-on, .toggler .toggler-off {
  opacity: 1;
  z-index: 2;
}

.toggler input:checked + label .toggler-off, .toggler input:not(:checked) + label .toggler-on {
  width: 0;
  height: 0;
  opacity: 0;
}

.toggler .path {
  fill: none;
  stroke: #fefefe;
  stroke-width: 7px;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

@-webkit-keyframes toggler-size {
  0%, 100% {
    width: 26px;
    height: 26px;
  }

  50% {
    width: 20px;
    height: 20px;
  }
}

@keyframes toggler-size {
  0%, 100% {
    width: 26px;
    height: 26px;
  }

  50% {
    width: 20px;
    height: 20px;
  }
}

@-webkit-keyframes toggler-size2 {
  0%, 100% {
    width: 26px;
    height: 26px;
  }

  50% {
    width: 20px;
    height: 20px;
  }
}

@keyframes toggler-size2 {
  0%, 100% {
    width: 26px;
    height: 26px;
  }

  50% {
    width: 20px;
    height: 20px;
  }
}
 

.transactionform input:not([type='checkbox']),
.transactionform select {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  box-sizing: border-box;
  border: 1px solid gray;
  border-radius: 4px;
  color: #555;
  font-size: 1em;
}
.transactionform input[type='submit']{
  cursor: pointer;
}
 .transactionform label {
  margin: 0 auto 20px;
  display: block;
  /* color: #fff; */
}


.loader {
  display: flex;
  align-items: center;
}

.bar {
  display: inline-block;
  width: 3px;
  height: 20px;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 10px;
  -webkit-animation: scale-up4 1s linear infinite;
          animation: scale-up4 1s linear infinite;
}

.bar:nth-child(2) {
  height: 35px;
  margin: 0 5px;
  -webkit-animation-delay: .25s;
          animation-delay: .25s;
}

.bar:nth-child(3) {
  -webkit-animation-delay: .5s;
          animation-delay: .5s;
}

@-webkit-keyframes scale-up4 {
  20% {
    background-color: #ffff;
    -webkit-transform: scaleY(1.5);
            transform: scaleY(1.5);
  }

  40% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}

@keyframes scale-up4 {
  20% {
    background-color: #ffff;
    -webkit-transform: scaleY(1.5);
            transform: scaleY(1.5);
  }

  40% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}

.loaderInmodal{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderInmodal::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background-color: rgba(0, 0, 0, 0.5);
}
.Login_login-form__1NBYb {
  max-width: 360px;
  margin: 60px auto;
  padding: 20px;
}
.Login_login-form__1NBYb label {
  display: block;
  margin: 30px auto;
}
.Login_login-form__1NBYb span {
  display: block;
  margin-bottom: 6px;
}
.Login_login-form__1NBYb input {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
}

.Signup_singup-form__2P93L {
  max-width: 360px;
  margin: 60px auto;
  padding: 20px;
}
.Signup_singup-form__2P93L label {
  display: block;
  margin: 30px auto;
}
.Signup_singup-form__2P93L span {
  display: block;
  margin-bottom: 6px;
}
.Signup_singup-form__2P93L input {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
}

.Home_container__3bftD {
  /* margin: 60px auto; */
}
.Home_content__18qU0 {
  padding-right: 30px;
}
.Home_sidebar__21xz5 {
  padding-left: 30px;
}
.Home_sidebar__21xz5 form {
  padding: 20px;
  background: #1f9751;
  border-radius: 10px;
}
/* have to apply style if type is not checkbox */
.Home_sidebar__21xz5 input:not([type='checkbox']),
.Home_sidebar__21xz5 select {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  box-sizing: border-box;
  border: 0;
  border-radius: 4px;
  color: #555;
  font-size: 1em;
}
.Home_sidebar__21xz5 label {
  margin: 0 auto 20px;
  display: block;
  color: #fff;
}
.Home_sidebar__21xz5 button {
  color: #fff;
  border: 2px solid #fff;
  padding: 6px 12px;
  background-color: transparent;
  font-size: 1em;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  width: 100%;
}
.Home_sidebar__21xz5 h3 {
  color: #1f9751;
  margin-bottom: 20px;
}
.Home_sidebar__21xz5 aside {
  margin-top: 40px;
  color: #555;
}
.Home_sidebar__21xz5 aside li {
  margin: 10px;
}

/*transactions*/
.Home_transactions__3_tqr li {
  margin: 30px auto;
  border: 1px solid #f2f2f2;
  box-shadow: 3px 3px 5px rgba(50, 50, 50, 0.1);
  padding: 20px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-left: 10px solid #1f9751;
}

.Home_transactions__3_tqr .Home_name__4a_Tj {
  color: #777;
  font-size: 1.4em;
}

.Home_transactions__3_tqr .Home_amount__1MW_9 {
  margin-left: auto;
  /* margin-right: 40px; */
  color: #777;
  font-weight: bold;
  font-size: 1.6em;
}

.Home_transactions__3_tqr button {
  position: absolute;
  top: 0;
  right: 0;
  background: #ddd;
  color: #777;
  border: none;
  padding: 12px 8px;
  text-align: center;
  line-height: 0;
  font-size: 0.9em;
  cursor: pointer;
}


.Home_subtext__2Z4XT{
  font-size: 0.8em;
  color: #aaa;
}
.Home_trbutton__1AftK{
  cursor: pointer;
  margin: 10px 20%;
  width: 60%;
  padding: 10px;
  background: #4dffe4;
  border: 1px solid;
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .Home_transactions__3_tqr .Home_name__4a_Tj {
    font-size: 1em;
  }
.Home_transactions__3_tqr .Home_amount__1MW_9 {
  font-size: 1em;
}
}


.Home_tabs__1gh0_{
  display: flex;
  justify-content: space-around;
}

.Home_tabLink__23yCi{
padding: 5px 20px;
width: 100%;
border: none;
height: 40px;
cursor: pointer;
}

.Home_tabLink__23yCi:hover{
  background: rgba(172, 255, 47, 0.709);
}

.Home_activeTabLink__1iz6b{
  background: rgba(172, 255, 47, 0.709);
}
/* navbar styles */
.Navbar_navbar__31-FB {
  width: 100%;
  background: #effaf0;
  padding: 20px 10px;
  box-sizing: border-box;
}
.Navbar_navbar__31-FB ul {
  display: flex;
  margin: 0 auto;
  max-width: 960px;
  align-items: center;
}
.Navbar_title__2CGsW {
  margin-right: auto;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1.2em;
}
.Navbar_navbar__31-FB button,
.Navbar_navbar__31-FB a {
  margin-left: 16px;
}
.Navbar_navbar__31-FB a {
  color: #333;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .Navbar_navbar__31-FB ul {
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }
  .Navbar_title__2CGsW {
    width: 100%;
    text-align: center;
  }
}
.style_heading__3DK_S{
    text-align: center;
    font-size: 25px;
}

a{
    text-decoration: none;
}
/*transactions*/
.style_transactions__1ltNu li {
    margin: 30px auto;
    border: 1px solid #f2f2f2;
    box-shadow: 3px 3px 5px rgba(50, 50, 50, 0.1);
    padding: 20px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    border-left: 10px solid #1f9751;
  }
  
  .style_transactions__1ltNu .style_name__YsJ30 {
    color: #181818;
    font-size: 1.4em;
  }
  
  .style_transactions__1ltNu .style_amount__gCwDv {
    margin-left: auto;
    /* margin-right: 40px; */
    color: #000000;
    font-weight: bold;
    font-size: 1.6em;
  }
  
  .style_transactions__1ltNu button {
    position: absolute;
    top: 0;
    right: 0;
    background: #ddd;
    color: #777;
    border: none;
    padding: 12px 8px;
    text-align: center;
    line-height: 0;
    font-size: 0.9em;
    cursor: pointer;
  }
  
  
  .style_subtext__2mUFP{
    font-size: 0.8em;
    color: #646464;
  }
