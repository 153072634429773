@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}
.btn {
  background: none;
  border: 2px solid #1f9751;
  padding: 6px 12px;
  border-radius: 4px;
  color: #1f9751;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn:hover {
  background: #1f9751;
  color: #fff;
}


.toggler {
  width: 72px;
  /* margin: 10px auto; */
  transform: scale(0.8);
}

.toggler input {
  display: none !important;
}

.toggler label {
  display: block;
  position: relative;
  width: 72px;
  height: 36px;
  border: 1px solid #d6d6d6;
  border-radius: 36px;
  background: #e4e8e8;
  cursor: pointer;
}

.toggler label::after {
  display: block;
  border-radius: 100%;
  background-color: #d7062a;
  content: '';
  animation-name: toggler-size;
  animation-duration: 0.15s;
  animation-timing-function: ease-out;
  animation-direction: forwards;
  animation-iteration-count: 1;
  animation-play-state: running;
}

.toggler label::after, .toggler label .toggler-on, .toggler label .toggler-off {
  position: absolute;
  top: 50%;
  left: 25%;
  width: 26px;
  height: 26px;
  transform: translateY(-50%) translateX(-50%);
  transition: left 0.15s ease-in-out, background-color 0.2s ease-out, width 0.15s ease-in-out, height 0.15s ease-in-out, opacity 0.15s ease-in-out;
}

.toggler input:checked + label::after, .toggler input:checked + label .toggler-on, .toggler input:checked + label .toggler-off {
  left: 75%;
}

.toggler input:checked + label::after {
  background-color: #50ac5d;
  animation-name: toggler-size2;
}

.toggler .toggler-on, .toggler .toggler-off {
  opacity: 1;
  z-index: 2;
}

.toggler input:checked + label .toggler-off, .toggler input:not(:checked) + label .toggler-on {
  width: 0;
  height: 0;
  opacity: 0;
}

.toggler .path {
  fill: none;
  stroke: #fefefe;
  stroke-width: 7px;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

@keyframes toggler-size {
  0%, 100% {
    width: 26px;
    height: 26px;
  }

  50% {
    width: 20px;
    height: 20px;
  }
}

@keyframes toggler-size2 {
  0%, 100% {
    width: 26px;
    height: 26px;
  }

  50% {
    width: 20px;
    height: 20px;
  }
}
 

.transactionform input:not([type='checkbox']),
.transactionform select {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  box-sizing: border-box;
  border: 1px solid gray;
  border-radius: 4px;
  color: #555;
  font-size: 1em;
}
.transactionform input[type='submit']{
  cursor: pointer;
}
 .transactionform label {
  margin: 0 auto 20px;
  display: block;
  /* color: #fff; */
}


.loader {
  display: flex;
  align-items: center;
}

.bar {
  display: inline-block;
  width: 3px;
  height: 20px;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.bar:nth-child(2) {
  height: 35px;
  margin: 0 5px;
  animation-delay: .25s;
}

.bar:nth-child(3) {
  animation-delay: .5s;
}

@keyframes scale-up4 {
  20% {
    background-color: #ffff;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}

.loaderInmodal{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderInmodal::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background-color: rgba(0, 0, 0, 0.5);
}